import React from 'react';

function Services() {
  return (
    <>
      <section id='services'>
        <div className="container-xxl service py-5">
          <div className="container">
            <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
              <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">Nos services</p>
              <h1 className="display-5 mb-5">Des services financiers géniaux pour les entreprises</h1>
            </div>
            <div className="row g-4 wow fadeInUp" data-wow-delay="0.3s">
              <div className="col-lg-4">
                <div className="nav nav-pills d-flex justify-content-between w-100 h-100 me-4">
                 
                  <button className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4 active"
                    data-bs-toggle="pill" data-bs-target="#tab-pane-2" type="button">
                    <h5 className="m-0"><i className="fa fa-bars text-primary me-3"></i>Placement de trésorerie</h5>
                  </button>                  
                  <button className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4"
                    data-bs-toggle="pill" data-bs-target="#tab-pane-4" type="button">
                    <h5 className="m-0"><i className="fa fa-bars text-primary me-3"></i>Prêts aux entreprises</h5>
                  </button>
                  <button className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4"
                    data-bs-toggle="pill" data-bs-target="#tab-pane-5" type="button">
                    <h5 className="m-0"><i className="fa fa-bars text-primary me-3"></i>Assistance en NTIC</h5>
                  </button>
                  <button className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4 "
                    data-bs-toggle="pill" data-bs-target="#tab-pane-1" type="button">
                    <h5 className="m-0"><i className="fa fa-bars text-primary me-3"></i>Planification financière</h5>
                  </button>
                  <button className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4"
                    data-bs-toggle="pill" data-bs-target="#tab-pane-3" type="button">
                    <h5 className="m-0"><i className="fa fa-bars text-primary me-3"></i>Conseil Financier</h5>
                  </button>
                  <button className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-0"
                    data-bs-toggle="pill" data-bs-target="#tab-pane-6" type="button">
                    <h5 className="m-0"><i className="fa fa-bars text-primary me-3"></i>Analyse d’affaires</h5>
                  </button>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="tab-content w-100">
                  
                  <div className="tab-pane fade show active" id="tab-pane-2">
                    <article className="row g-4">
                      <div className="col-md-6" style={{ minHeight: '350px' }}>
                        <div className="position-relative h-100">
                          <img className="position-absolute rounded w-100 h-100" src="img/service-2.jpeg"
                            style={{ objectFit: 'cover' }} alt="Placement de trésorerie" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <h2 className="mb-4">Placement de trésorerie</h2>
                        <p className="mb-4">Profitez de nos solutions pour optimiser vos liquidités, incluant des placements sécurisés, des options de crédit et des avances de trésorerie sur mesure.</p>
                        <ul style={{listStyle:'none'}}>
                          <li><i className="fa fa-check text-primary me-3"></i>Gestion de Portefeuille</li>
                          <li><i className="fa fa-check text-primary me-3"></i>Placement à Court Terme</li>
                          <li><i className="fa fa-check text-primary me-3"></i>Investissements à Long Terme</li>
                        </ul>
                        <p>Chez Zig Investment, nous comprenons que choisir où investir son argent est une décision cruciale. C’est pourquoi nous offrons un taux d’intérêt compétitif de 12% par an, 
                          conçu pour maximiser les rendements tout en soutenant la croissance économique locale en Haïti.</p>
                      </div>
                    </article>
                  </div>
                  
                  <div className="tab-pane fade" id="tab-pane-4">
                    <article className="row g-4">
                      <div className="col-md-6" style={{ minHeight: '350px' }}>
                        <div className="position-relative h-100">
                          <img className="position-absolute rounded w-100 h-100" src="img/service-4.jpg"
                            style={{ objectFit: 'cover' }} alt="Prêts aux entreprises" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <h2 className="mb-4">Prêts aux entreprises</h2>
                        <p className="mb-4">Bénéficiez de solutions de financement adaptées à vos besoins pour soutenir la croissance et le développement de votre entreprise.</p>
                        <p>Chez Zig Investment, nous reconnaissons que le soutien aux entreprises locales est essentiel pour stimuler l'économie et 
                            favoriser un développement durable en Haïti. Pour renforcer notre écosystème d'affaires, nous proposons des prêts aux entreprises affiliées, 
                            leur offrant les ressources nécessaires pour croître et prospérer.</p>
                        <ul style={{listStyle:'none'}}>                          
                          <li><i className="fa fa-check text-primary me-3"></i>Prêts Sécurisés</li>
                          <li><i className="fa fa-check text-primary me-3"></i>Facilités de Crédit</li>
                          <li><i className="fa fa-check text-primary me-3"></i>Avances de Trésorerie</li>
                        </ul>
                      </div>
                    </article>
                  </div>
                  <div className="tab-pane fade" id="tab-pane-5">
                    <article className="row g-4">
                      <div className="col-md-6" style={{ minHeight: '350px' }}>
                        <div className="position-relative h-100">
                          <img className="position-absolute rounded w-100 h-100" src="img/assistance_ntic.jpeg"
                            style={{ objectFit: 'cover' }} alt="Assistance NTIC" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <h2 className="mb-4">Assistance NTIC</h2>
                        <p className="mb-4">Profitez de solutions technologiques adaptées pour optimiser les performances de votre entreprise.</p>
                        <p>Chez Zig Investment, nous comprenons que l'innovation et l'utilisation efficace des Nouvelles Technologies
                           de l'Information et de la Communication (NTIC) sont cruciales pour la compétitivité et le développement des 
                           entreprises. C'est pourquoi nous offrons un service d'assistance complet pour aider les entreprises affiliées 
                           à intégrer les NTIC dans leurs opérations.</p>
                        <ul style={{listStyle:'none'}}>
                          <li><i className="fa fa-check text-primary me-3"></i>Support Informatique</li>
                          <li><i className="fa fa-check text-primary me-3"></i>Gestion des Réseaux</li>
                          <li><i className="fa fa-check text-primary me-3"></i>Sécurité des Données</li>
                        </ul>
                      </div>
                    </article>
                  </div>
                  <div className="tab-pane fade" id="tab-pane-1">
                    <article className="row g-4">
                      <div className="col-md-6" style={{ minHeight: '350px' }}>
                        <div className="position-relative h-100">
                          <img className="position-absolute rounded w-100 h-100" src="img/service-1.jpeg"
                            style={{ objectFit: 'cover' }} alt="Planification financière" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <h2 className="mb-4">Planification financière</h2>
                        <p className="mb-4">La planification financière est essentielle pour assurer la sécurité et la croissance de votre patrimoine. En établissant des objectifs clairs et en adoptant des stratégies adaptées, vous pouvez atteindre vos aspirations financières avec confiance et tranquillité d'esprit.</p>
                        <ul style={{listStyle:'none'}}>
                          <li><i className="fa fa-check text-primary me-3"></i>Planification de la Retraite</li>
                          <li><i className="fa fa-check text-primary me-3"></i>Épargne et Investissement</li>
                          <li><i className="fa fa-check text-primary me-3"></i>Assurance et Gestion des Risques</li>
                        </ul>
                      </div>
                    </article>
                  </div>
                  <div className="tab-pane fade" id="tab-pane-6">
                    <article className="row g-4">
                      <div className="col-md-6" style={{ minHeight: '350px' }}>
                        <div className="position-relative h-100">
                          <img className="position-absolute rounded w-100 h-100" src="img/analyse_d_affaires.jpeg"
                            style={{ objectFit: 'cover' }} alt="Analyse d’affaires" />
                      </div>
                      </div>
                      <div className="col-md-6">
                        <h2 className="mb-4">Analyse d’affaires</h2>
                        <p className="mb-4">Nous sommes ravis d'annoncer le lancement de notre nouveau service d'analyse d’affaires cette année. Bénéficiez d'analyses approfondies pour prendre des décisions stratégiques éclairées.</p>
                        <ul style={{listStyle:'none'}}>
                          <li><i className="fa fa-check text-primary me-3"></i>Analyse de Marché</li>
                          <li><i className="fa fa-check text-primary me-3"></i>Études de Faisabilité</li>
                          <li><i className="fa fa-check text-primary me-3"></i>Optimisation des Processus</li>
                        </ul>
                      </div>
                    </article>
                  </div>
                  <div className="tab-pane fade" id="tab-pane-3">
                    <article className="row g-4">
                      <div className="col-md-6" style={{ minHeight: '350px' }}>
                        <div className="position-relative h-100">
                          <img className="position-absolute rounded w-100 h-100" src="img/service-3.jpg"
                            style={{ objectFit: 'cover' }} alt="Conseil Financier" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <h2 className="mb-4">Conseil Financier</h2>
                        <p className="mb-4">Profitez de notre expertise pour optimiser vos finances, avec des conseils personnalisés, des stratégies d'investissement et une gestion de patrimoine efficace.</p>
                        <ul style={{listStyle:'none'}}>
                          <li><i className="fa fa-check text-primary me-3"></i>Conseils Personnalisés</li>
                          <li><i className="fa fa-check text-primary me-3"></i>Stratégies d'Investissement</li>
                          <li><i className="fa fa-check text-primary me-3"></i>Gestion de Patrimoine</li>
                        </ul>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Services;
