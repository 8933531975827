import { useState, useEffect } from "react";
import { Link } from "react-scroll";

function Navbar() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate loading completion
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000); // Adjust the timeout as needed

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {isLoading && (
                <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                    <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}></div>
                </div>
            )}
            <div className="container-fluid fixed-top px-0 wow fadeIn" data-wow-delay="0.1s">
                <div className="top-bar row gx-0 align-items-center d-none d-lg-flex">
                    <div className="col-lg-6 px-5 text-start">
                        <small><i className="fa fa-map-marker-alt text-primary me-2"></i>123 Rue 23 C, Cap-Haitien, HAITI</small>
                        <small className="ms-4"><i className="fa fa-clock text-primary me-2"></i>9.00 am - 4.00 pm</small>
                    </div>
                    <div className="col-lg-6 px-5 text-end">
                        <small><i className="fa fa-envelope text-primary me-2"></i>info@ziginvestment.com</small>
                        <small className="ms-4"><i className="fa fa-phone-alt text-primary me-2"></i>+509 3769 4090</small>
                    </div>
                </div>

                <nav className="navbar navbar-expand-lg navbar-light py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s">
                    <Link to="home" className="navbar-brand ms-4 ms-lg-0" spy={true} smooth={true} offset={-70} duration={500}>
                        <h1 className="display-5 text-primary m-0">Zig Investment</h1>
                    </Link>
                    <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav ms-auto p-4 p-lg-0">
                            <Link to="carousel" className="nav-item nav-link active" spy={true} smooth={true} offset={-70} duration={500}>Accueil</Link>
                            <Link to="about" className="nav-item nav-link" spy={true} smooth={true} offset={-70} duration={500}>A Propos</Link>
                            <Link to="services" className="nav-item nav-link" spy={true} smooth={true} offset={-70} duration={500}>Services</Link>
                            <Link to="team" className="nav-item nav-link" spy={true} smooth={true} offset={-70} duration={500}>Équipe</Link>
                            <Link to="contact" className="nav-item nav-link" spy={true} smooth={true} offset={-70} duration={500}>Contact</Link>
                        </div>
                        <div className="d-none d-lg-flex ms-2">
                            <a className="btn btn-light btn-sm-square rounded-circle ms-3" href="https://www.facebook.com/100066627170022" aria-label="Facebook">
                                <small className="fab fa-facebook-f text-primary"></small>
                            </a>
                            <a className="btn btn-light btn-sm-square rounded-circle ms-3" href="https://www.twitter.com/ZigInvestment" aria-label="Twitter">
                                <small className="fab fa-twitter text-primary"></small>
                            </a>
                            <a className="btn btn-light btn-sm-square rounded-circle ms-3" href="https://www.instagram.com/ziginvestment" aria-label="Instagram">
                                <small className="fab fa-instagram text-primary"></small>
                            </a>
                            <a className="btn btn-light btn-sm-square rounded-circle ms-3" href="https://www.tiktok.com/@ziginvestment" aria-label="TikTok">
                                <small className="fab fa-brands fa-tiktok text-primary"></small>
                            </a>
                            <a className="btn btn-light btn-sm-square rounded-circle ms-3" href="https://linkedin.com/company/zig-investment/" aria-label="LinkedIn">
                                <small className="fab fa-linkedin-in text-primary"></small>
                            </a>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
}

export default Navbar;
