import React from "react";
import Typewriter from 'typewriter-effect';

function Carousel() {
  return (
    <section id="carousel">
      <div className="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="w-100" src="img/carousel-1.png" alt="First slide" />
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-start">
                    <div className="col-lg-8">
                      <p className="d-inline-block border border-primary rounded text-primary fw-semi-bold py-1 px-3 animated slideInDown">
                        Bienvenue Chez ZIG Investment
                      </p>
                      <h6 className="typewriter-text display-1 mb-4 animated slideInDown" style={{fontSize:'60px'}}>
                        <Typewriter
                          options={{
                            autoStart: true,
                            loop: true,
                            delay: 40,
                          }}
                          onInit={(typewriter) => {
                            typewriter
                              .typeString("Votre situation financière est notre Priorité.")
                              .pauseFor(3000)
                              .deleteAll()
                              .typeString("Un véritable soutien financier construit pour vous.")
                              .pauseFor(3000)
                              .deleteAll()
                              .start();
                          }}
                        />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img className="w-100" src="img/carousel-2.png" alt="Second slide" />
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-start">
                    <div className="col-lg-7">
                      <p className="d-inline-block border border-primary rounded text-primary fw-semi-bold py-1 px-3 animated slideInDown">
                        Bienvenue Chez ZIG Investment
                      </p>
                      <h6 className="typewriter-text display-1 mb-4 animated slideInDown" style={{fontSize:'60px'}}>
                        <Typewriter
                          options={{
                            autoStart: true,
                            loop: true,
                            delay: 40,
                          }}
                          onInit={(typewriter) => {
                            typewriter
                              .typeString("Votre situation financière est notre Priorité.")
                              .pauseFor(3000)
                              .deleteAll()
                              .typeString("Un véritable soutien financier construit pour vous.")
                              .pauseFor(3000)
                              .deleteAll()
                              .start();
                          }}
                        />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </section>
  );
}

export default Carousel;
