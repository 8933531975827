import React from 'react';

function About() {
  return (
    <>
      <section id='about'>
        <div className="container-xxl py-5">
          <div className="container">
            <div className="row g-4 align-items-end mb-4">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <img className="img-fluid rounded" src="img/about.png" alt='about_image'/>
              </div>
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">Qui sommes-nous</p>
                <h1 className="display-5 mb-4">Un écosystème d'affaires international innovant</h1>
                <p className="mb-4">ZIG Investment</p>
                <div className="border rounded p-4">
                  <nav>
                    <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                    <button className="nav-link fw-semi-bold active" id="nav-contexte-tab" data-bs-toggle="tab"
                              data-bs-target="#nav-contexte" type="button" role="tab" aria-controls="nav-contexte"
                              aria-selected="false">Contexte</button>
                      <button className="nav-link fw-semi-bold " id="nav-mission-tab" data-bs-toggle="tab"
                              data-bs-target="#nav-mission" type="button" role="tab" aria-controls="nav-mission"
                              aria-selected="false">Mission</button>
                      <button className="nav-link fw-semi-bold" id="nav-vision-tab" data-bs-toggle="tab"
                              data-bs-target="#nav-vision" type="button" role="tab" aria-controls="nav-vision"
                              aria-selected="false">Vision</button>
                     
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-contexte" role="tabpanel" aria-labelledby="nav-contexte-tab">
                      <p>Zig Investment est né de la nécessité de combler le fossé entre le potentiel économique inexploité 
                      et les défis structurels en Haïti. En mobilisant des fonds locaux et en exploitant la technologie moderne, 
                      Zig Investment vise à créer un écosystème d'investissement inclusif et dynamique. Ce projet ambitionne de 
                      transformer les petites épargnes en puissants moteurs de développement économique, tout en apportant des 
                      rendements attractifs aux investisseurs et des améliorations tangibles aux communautés locales.</p>

                      <p className="mb-0">En combinant l'innovation financière, l'engagement communautaire et une approche axée 
                      sur le développement durable, Zig Investment se positionne comme une solution clé pour relever les défis économiques d'Haïti 
                      tout en capturant les opportunités de croissance. </p>
                      </div>
                    <div className="tab-pane fade" id="nav-mission" role="tabpanel" aria-labelledby="nav-mission-tab">
                    <p>Notre mission est de créer un environnement fiable et favorable à l’investissement, où les investisseurs 
                        peuvent prospérer en toute confiance. Nous nous engageons à fournir des opportunités d’investissement attrayantes, 
                        soutenues par une transparence totale, une intégrité inébranlable et un engagement envers l'excellence. En travaillant en partenariat 
                        avec nos investisseurs.</p>
                      <p className="mb-0">Cette mission élargit votre engagement en mettant l’accent sur la création d’un environnement inclusif où les obstacles à l’accès 
                      à l’investissement sont surmontés et où chacun a la possibilité de participer à la création de richesse financière.</p>
                    </div>
                    <div className="tab-pane fade" id="nav-vision" role="tabpanel" aria-labelledby="nav-vision-tab">
                      <p>Notre vision est de bâtir un avenir financier solide en investissant dans des opportunités qui non seulement
                         génèrent des rendements significatifs, mais qui contribuent également au développement durable et à 
                         l’amélioration des communautés.</p>
                      <p className="mb-0">Engagement envers l’Espoir et l’Avenir, Nous croyons fermement que chaque investissement a 
                      le potentiel de créer de l’espoir et de contribuer à façonner un avenir prospère. Notre engagement envers l’intégrité, la transparence et l’impact positif guide chacune de nos décisions et actions dans ce Contexte Économique et Social.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border rounded p-4 wow fadeInUp" data-wow-delay="0.1s">
              <div className="row g-4">
                <div className="col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                  <div className="h-100">
                    <div className="d-flex">
                      <div className="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                        <i className="fa fa-times text-white"></i>
                      </div>
                      <div className="ps-3">
                        <h4>Pas de frais cachés</h4>
                        <span>Profitez d'une transparence totale dans nos services financiers.</span>
                      </div>
                      <div className="border-end d-none d-lg-block p-2"></div>
                    </div>
                    <div className="border-bottom mt-4 d-block d-lg-none"></div>
                  </div>
                </div>
                <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                  <div className="h-100">
                    <div className="d-flex">
                      <div className="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                        <i className="fa fa-users text-white"></i>
                      </div>
                      <div className="ps-3">
                        <h4>Équipe dédiée</h4>
                        <span>Profitez de l'expertise de notre équipe dévouée pour répondre à vos besoins financiers.</span>
                      </div>
                      <div className="border-end d-none d-lg-block"></div>
                    </div>
                    <div className="border-bottom mt-4 d-block d-lg-none"></div>
                  </div>
                </div>
                <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                  <div className="h-100">
                    <div className="d-flex">
                      <div className="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                        <i className="fa fa-phone text-white"></i>
                      </div>
                      <div className="ps-3">
                        <h4>Disponible 24h/24 et 7j/7</h4>
                        <span>Peu importe quand vous avez besoin de nous, notre équipe est là pour vous aider.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
