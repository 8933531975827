import React from 'react';

function Team() {
  return (
    <section id='team'>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
            <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">Notre équipe</p>
            <h5 className="display-5 mb-5">Équipe exclusive</h5>
          </div>
          <div className="row g-4">
            {/* Mackenson */}
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="team-item">
                <img className="img-fluid rounded" src="img/Mackenson.jpeg" alt="Mackenson MESIDOR" />
                <div className="team-text">
                  <h6 className="mb-0 nowrap">Mackenson MESIDOR</h6>
                  <div className="team-social d-flex">
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.facebook.com/mesidor.mackenson"><i className="fab fa-facebook-f"></i></a>
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.twitter.com/mmesidor7"><i className="fab fa-twitter"></i></a>
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.linkedin.com/in/mackenson-mesidor-42129b181"><i className='fab fa-linkedin-in'></i></a>
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.instagram.com/mesidormackensonlegrand"><i className="fab fa-instagram"></i></a>
                  </div>
                </div>
              </div>
            </div>
            {/* Mackenson */}
            {/* Kara Lee */}
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <img className="img-fluid rounded" src="img/Kara.jpeg" alt="Kara Lee RIDORE" />
                <div className="team-text">
                  <h6 className="mb-0 nowrap">Kara Lee RIDORE</h6>
                  <div className="team-social d-flex">
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.facebook.com/karalee32"><i className="fab fa-facebook-f"></i></a>
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.twitter.com/KaraRidore"><i className="fab fa-twitter"></i></a>
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.linkedin.com/in/kara-lee-ridoré-95b180272"><i className='fab fa-linkedin-in'></i></a>
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.instagram.com/kara_lee_ridore"><i className="fab fa-instagram"></i></a>
                  </div>
                </div>
              </div>
            </div>
            {/* Kara Lee */}
            {/* Witchy */}
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="team-item">
                <img className="img-fluid rounded" src="img/Witchy.jpeg" alt="Witchy JEAN" />
                <div className="team-text">
                  <h6 className="mb-0 nowrap">Witchy JEAN</h6>
                  <div className="team-social d-flex">
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.facebook.com/ingwitchy"><i className="fab fa-facebook-f"></i></a>
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.twitter.com/ing_jeanwitchy"><i className="fab fa-twitter"></i></a>
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.linkedin.com/in/jeanwitchy"><i className='fab fa-linkedin-in'></i></a>
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.instagram.com/witchyofficiel"><i className="fab fa-instagram"></i></a>
                  </div>
                </div>
              </div>
            </div>
            {/* Witchy */}
            {/* Rodolph */}
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
              <div className="team-item">
                <img className="img-fluid rounded" src="img/Rodolph.jpeg" alt="Rodolph Phayendy Delon FIDELE" />
                <div className="team-text">
                  <h6 className="mb-0 nowrap">Rodolph Phayendy Delon FIDELE</h6>
                  <div className="team-social d-flex">
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.facebook.com/dolphfi"><i className="fab fa-facebook-f"></i></a>
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.twitter.com/IngRodolph"><i className="fab fa-twitter"></i></a>
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.linkedin.com/in/dolphfi"><i className='fab fa-linkedin-in'></i></a>
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.instagram.com/dolphfi_"><i className="fab fa-instagram"></i></a>
                  </div>
                </div>
              </div>
            </div>
            {/* Rodolph */}
            {/* Wislin */}
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.9s">
              <div className="team-item">
                <img className="img-fluid rounded" src="img/Wislin.jpeg" alt="Wislin Herntz PROPHETE" />
                <div className="team-text">
                  <h6 className="mb-0 nowrap">Wislin Herntz PROPHETE</h6>
                  <div className="team-social d-flex">
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.facebook.com/WislinHerntz"><i className="fab fa-facebook-f"></i></a>
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.twitter.com/HerntzWislin"><i className="fab fa-twitter"></i></a>
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.linkedin.com/in/prophète-wislin-herntz"><i className='fab fa-linkedin-in'></i></a>
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.instagram.com/wisherntz"><i className="fab fa-instagram"></i></a>
                  </div>
                </div>
              </div>
            </div>
            {/* Wislin */}
            {/* Light Pic */}
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="1.1s">
              <div className="team-item">
                <img className="img-fluid rounded" src="img/Light_pic.jpeg" alt="Schleyden JEAN-BAPTISTE" />
                <div className="team-text">
                  <h6 className="mb-0 nowrap">Schleyden JEAN-BAPTISTE</h6>
                  <div className="team-social d-flex">
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.facebook.com/jeanbaptisteschleyden"><i className="fab fa-facebook-f"></i></a>
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.twitter.com/username"><i className="fab fa-twitter"></i></a>
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.linkedin.com/in/username"><i className='fab fa-linkedin-in'></i></a>
                    <a className="btn btn-square rounded-circle mx-1" href="https://www.instagram.com/light_pic__"><i className="fab fa-instagram"></i></a>
                  </div>
                </div>
              </div>
            </div>
            {/* Light Pic */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
