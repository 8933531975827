import About from "../pages/About";
import Affiliation from "../pages/Affiliation";
import Carousel from "../pages/Carousel";
import Contact from "../pages/Contact";
import Footer from "../pages/Footer";
// import Modalinfo from "../pages/Modalinfo";
import Services from "../pages/Services";
import Team from "../pages/Team";



export default function Home(){
    return (
        <>
            {/* <Modalinfo/> */}
            <Carousel />
            <About />
            <Services />
            <Team />
            <Contact />
            <Affiliation/>
            <Footer/>
        </>
    );
}