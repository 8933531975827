import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_2blkwsn', 'template_woz5v59', form.current, 'zgGjAmIbgIaO8d7mo')
      .then(
        () => {
          console.log('SUCCESS!');
          Swal.fire({
            title: "Message envoyé avec succès",
            icon: "success",
            toast: true,
            timer: 3000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
          });
          form.current.reset();
        },
        (error) => {
          console.log('FAILED...', error.text);
          Swal.fire({
            title: "Échec de l'envoi",
            icon: "error",
            toast: true,
            timer: 3000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      );
  };

  return (
    <section id='contact'>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">Contact</p>
              <h5 className="display-5 mb-4">Si vous avez des questions, veuillez nous contacter</h5>
              <form ref={form} onSubmit={sendEmail}>
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="text" className="form-control" id="name" placeholder="Your Name" name='name' required/>
                      <label htmlFor="name">Votre Nom</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="email" className="form-control" id="email" placeholder="Your Email" name='email' required/>
                      <label htmlFor="email">Votre Email</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input type="text" className="form-control" id="subject" placeholder="Subject" name='subject' required/>
                      <label htmlFor="subject">Sujet</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea className="form-control" required placeholder="Leave a message here" name='message' id="message" style={{ height: '100px' }}></textarea>
                      <label htmlFor="message">Message</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="btn btn-primary py-3 px-5" type="submit">Envoyer</button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s" style={{ minHeight: '450px' }}>
              <div className="position-relative rounded overflow-hidden h-100">
                <iframe
                  className="position-relative w-100 h-100"
                  src='https://maps.google.com/maps?q=123,%20Rue%2023%20Cap-Haitien&t=&z=13&ie=UTF8&iwloc=&output=embed'
                  frameBorder="0"
                  style={{ minHeight: '450px', border: '0' }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                  title="Map of 123 Rue 23 C, Cap-Haitien, Haiti"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
