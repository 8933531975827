import React from 'react'

function Affiliation() {
    return (
        <section id='contact'>
          <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
                <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">Entreprise Affiliée</p>
                </div>
                <div className="row g-4">
                    {/* Bousanm */}
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="team-item">
                            <div className="team-text">
                                <h6 className="mb-0 nowrap">
                                    <a href="https://bousanm.com" target="_blank" rel="noopener noreferrer">
                                        <img src="img/bousanm.png" className="img-fluid rounded" alt="Portfolio Bousanm"/>
                                    </a>
                                </h6>
                            </div>
                        </div>
                    </div>
                    {/* Bousanm */}
                    {/* CIRE */}
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="team-item">
                            <div className="team-text">
                                <h6 className="mb-0 nowrap"><img className="img-fluid rounded" src="img/CIRE.png" alt="Centre informatique de reparation electronique"/></h6>
                            </div>
                        </div>
                    </div>
                    {/* CIRE */}
                    {/* Metres Store */}
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="team-item">
                            <div className="team-text">
                                <h6 className="mb-0 nowrap"><img src="img/metres_store.png" className="img-fluid rounded" alt="Metres Store & Beauty Center"/></h6>
                            </div>
                        </div>
                    </div>
                    {/* Metres Store  */}
                    {/* Kolabo Tech */}
                    <div className="col-lg-4 col-md-6 wow fadeInUp " data-wow-delay="0.1s">
                        <div className="team-item">
                            <div className="team-text">
                                <h6 className="mb-0 nowrap"><img src="img/Kolabo.png" className="img-fluid rounded " alt="Kolabo Technology Enterprise"/></h6>
                                {/* logo Kolabo Tech */}
                            </div>
                        </div>
                    </div>
                    {/* Kolabo Tech */}
                    {/* H-Design */}
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="team-item">
                            <div className="team-text">
                                <h6 className="mb-0 nowrap"><img src="img/H_logo.png" className="img-fluid rounded" alt="H Design Grapical Enterprise"/></h6>
                                {/* logo H-Design  */}
                            </div>
                        </div>
                    </div>
                    {/* H-Design  */}
                    {/* Sphere Tech */}
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="team-item">
                            <div className="team-text">
                                <h6 className="mb-0 nowrap"><img src="img/sphere.png" className="img-fluid rounded" alt="Sphere Technology Sevices"/></h6>
                            </div>
                        </div>
                    </div>
                    {/* Sphere Tech */}
                </div>
            </div>
          </div>
        </section>
      );
}

export default Affiliation