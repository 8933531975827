import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/HomeScreen';
import Navbar from './components/pages/Navbar';
import ScrollToTop from './components/pages/ScrollToTop';



function App() {
  return (
    <>
      <Router>
        <div>
          <ScrollToTop />
          <Navbar/>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="*" element={'<div>404 Not Found<div/>'}/>
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
