import React from "react";
import { Link } from "react-scroll";

function Footer() {
  return (
    <>
      <div className="container-fluid bg-dark text-light footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-5 col-md-6">
              <h4 className="text-white mb-4">Nos bureaux</h4>
              <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>123 Rue 23 C, Cap-Haitien, HAITI</p>
              <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>111 Rue 6 J, Cap-Haitien, HAITI</p>
              <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>9.00 am - 4.00 pm</p>
              <p className="mb-2"><i className="fa fa-envelope me-3"></i>info@ziginvestment.com</p>
              <div className="d-flex pt-2">
                {/* <a className="btn btn-square btn-outline-light rounded-circle me-2" href="https://twitter.com"><i className="fab fa-twitter"></i></a> */}
                {/* <a className="btn btn-square btn-outline-light rounded-circle me-2" href="https://facebook.com"><i className="fab fa-facebook-f"></i></a> */}
                {/* <a className="btn btn-square btn-outline-light rounded-circle me-2" href="https://youtube.com"><i className="fab fa-tiktok"></i></a> */}
                {/* <a className="btn btn-square btn-outline-light rounded-circle me-2" href="https://linkedin.com/company/zig-investment/"><i className="fab fa-linkedin-in"></i></a> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <h4 className="text-white mb-4">Services</h4>
              <h6 className="btn btn-link" >Planification Financière</h6>
              <h6 className="btn btn-link" >Placement de trésorerie</h6>
              <h6 className="btn btn-link" >Conseil Financier</h6>
              <h6 className="btn btn-link" >Prêts aux entreprises</h6>
              <h6 className="btn btn-link" >Assistance en NTIC</h6>
              <h6 className="btn btn-link" >Analyse d’affaires</h6>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-4">Liens rapides</h4>
              <Link to="about" className="btn btn-link" spy={true} smooth={true} offset={-70} duration={500}>A Propos</Link>
              <Link to="services" className="btn btn-link" spy={true} smooth={true} offset={-70} duration={500}>Services</Link>
              <Link to="team" className="btn btn-link" spy={true} smooth={true} offset={-70} duration={500}>Équipe</Link>
              <Link to="contact" className="btn btn-link" spy={true} smooth={true} offset={-70} duration={500}>Contact</Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid copyright py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy; ZIG Investment, All Right Reserved.
            </div>
          </div>
        </div>
      </div>

      <a href="/" className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><i className="bi bi-arrow-up"></i></a>
    </>
  );
}

export default Footer;
